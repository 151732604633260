import { LandingTile } from "./LandingTile";
import { GettingStartedTile } from "./GettingStarted";
import { SpeakingLangTile } from "./SpeakingLang";
import { Community } from "./Community";
import styled from "styled-components";

const Foot = styled.div`
    padding: 0 0 10px 0;
    font-size: 1.25rem;
    color: #8075FF;
    &:hover {
        cursor: pointer;
    }
    &:last-child {
        padding-top: 20px;
    }
`;

export const Home = () => <>
    <LandingTile/>
    <GettingStartedTile/>
    <SpeakingLangTile/>
    <Community/>
    <Foot onClick={() => {window.scrollTo({
        top: 0,
        left: 0,
        behavior: "smooth",
    })}}>Return To Top</Foot>
</>
